import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Tab,
  Tabs,
  Typography,
  Box,
  Container,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect, useMemo } from 'react';
import { ShimmerTable } from 'react-shimmer-effects';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//
import {
  Button,
  Select as SelectBox,
  Autocomplete,
  DataGrid,
  DatePicker,
} from 'features/base/components';
import { capitalizeFirstLetter, toUpperCase } from 'features/base/helpers/strings';
import { supportTicketActions } from 'features/support-tickets/slice';
import { DATE_LANGUAGE, DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import { selectUserId, selectAuthUser } from 'features/base/auth/selectors';
import { USER_TYPES } from 'features/base/constants/user-types';
import {
  selectSupportTickets,
  selectLoader,
  selectRelatedProjectsClient,
  selectManagingProjects,
  selectTicketStatusLoader,
  selectUpdatedSupportTicket,
  selectItSupportProjects,
} from 'features/support-tickets/selectors';
import { SEVERITY_TYPES } from 'features/base/constants/severity-types';
import { SUPPORT_TICKET_FILTER_TYPES } from 'features/base/constants/support-ticket-filter-types';
import { SUPPORT_TICKET_STATUS } from 'features/base/constants/support-ticket-status-types';
import { SUPPORT_TICKET_SORTBY_FIELDS } from 'features/base/constants/support-ticket-sortby-fields';
import { SORT_TYPES } from 'features/base/constants/sort-types';
import { SUPPORT_TICKET_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import PermissionWrapper from 'features/base/auth/components/permission-wrapper';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { selectNotification } from 'features/base/notifications/selectors';
import ERROR_TYPES from 'features/base/constants/error-types';
import TOAST_TYPES from 'features/base/constants/toast-types';
import {
  SUPPORT_TICKET_HANDLING_TABLE_COLUMNS,
  SUPPORT_TICKET_RAISED_TABLE_COLUMNS,
} from 'features/base/utils/tables';
import COLORS from 'features/base/constants/colors';
import ConfirmationPopup from 'features/base/components/confirmation-popup';
import ROUTES from 'features/base/constants/routes';
import AddSupportTicketsPopup from '../add-support-tickets-modal';
import CommentSectionPopup from '../comment-section-modal';
import './index.scss';

/**
 * Component that defines the entire support ticket view
 * @returns MUI Grid with the support ticket view
 */
const SupportTicketView = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  const ticketReadPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.TICKET_HANDLING,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  //
  const dispatch = useDispatch();
  //
  const notification = useSelector(selectNotification);
  const userId = useSelector(selectUserId);
  const authUser = useSelector(selectAuthUser);
  const supportTickets = useSelector(selectSupportTickets);
  const isLoading = useSelector(selectLoader);
  const ticketStatusLoading = useSelector(selectTicketStatusLoader);
  const updatedSupportTicket = useSelector(selectUpdatedSupportTicket);
  const relatedProjectsClient = useSelector(selectRelatedProjectsClient);
  const managingProjects = useSelector(selectManagingProjects);
  const itSupportProjects = useSelector(selectItSupportProjects);
  //
  const isFirst = useIsInitialize(supportTickets);
  //
  const [searchParams, setSearchParams] = useSearchParams();
  //
  const [type, setType] = useState(searchParams.get('type') || SUPPORT_TICKET_FILTER_TYPES.RAISED);
  const [severityValue, setSeverity] = useState('All');
  const [statusValue, setStatus] = useState('All');
  const [projectValue, setProject] = useState({
    id: 'All',
    label: 'All',
  });
  const [selectedProjectId, setProjectId] = useState(null);
  const [pageController, setPageController] = useState({
    sortBy: 'desc',
    sortByField: 'updatedAt',
    rowsPerPage: SUPPORT_TICKET_PAGINATION_LIMIT,
  });
  const [isResolvePopupOpen, setIsResolvePopupOpen] = useState(false);
  const [ticketToResolve, setTicketToResolve] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [commentSectionModelOpen, setCommentSectionModelOpen] = useState(false);
  const [ticketId, setTicketId] = useState('');
  const [page, setPage] = useState(1);
  const [monthChange, setMonthChange] = useState(null);
  const [APIformattedMonth, setAPIformattedMonth] = useState(null);
  //
  const params = useMemo(
    () => ({
      userId,
      type,
      severity: severityValue,
      projectId: selectedProjectId,
      status: statusValue,
      month: APIformattedMonth,
      limit: pageController.rowsPerPage,
      page,
      sortBy: pageController.sortBy,
      sortByField: pageController.sortByField,
    }),
    [severityValue, statusValue, selectedProjectId, type, pageController, page, APIformattedMonth]
  );
  //
  const handleOnConfirmationSubmit = () => {
    dispatch(
      supportTicketActions.updateResolvedStatus({
        id: ticketToResolve?.id,
        acknowledged: {
          isAcknowledged: true,
        },
      })
    );
  };
  //
  const internal =
    authUser?.type === USER_TYPES.INTERNAL ||
    authUser?.type === USER_TYPES.INTERN ||
    authUser?.type === USER_TYPES.CONTRACT;
  //
  const getOptions = () => {
    if (internal) {
      if (type === SUPPORT_TICKET_FILTER_TYPES.HANDLING) {
        return [
          {
            id: 'All',
            label: 'All',
          },
          ...(managingProjects?.map((project) => ({
            id: project?.id,
            label: project?.name,
          })) ?? []),
        ];
      }
      if (type === SUPPORT_TICKET_FILTER_TYPES.RAISED) {
        return [
          {
            id: 'All',
            label: 'All',
          },
          ...(itSupportProjects?.map((project) => ({
            id: project?.id,
            label: project?.name,
          })) ?? []),
        ];
      }
    }
    return [
      {
        id: 'All',
        label: 'All',
      },
      ...(relatedProjectsClient.map((record) => ({
        id: record?.projectId?.id,
        label: record?.projectId?.name,
      })) ?? []),
    ];
  };
  //
  const fetchNextPage = () => {
    setPage(supportTickets?.page ? supportTickets.page + 1 : 0);
  };
  //
  const initialize = () => {
    setPage(1);
    dispatch(supportTicketActions.resetSupportTickets());
  };
  //
  useEffect(() => {
    if (internal) {
      dispatch(
        supportTicketActions.getItSupportProjects({ query: `itSupport=${true}&pagination=false` })
      );
      dispatch(
        supportTicketActions.getManagingProjects({
          query: `ticketAssignees=${userId}&pagination=false`,
        })
      );
    } else {
      dispatch(
        supportTicketActions.getProjectsOfClient({
          query: `userId=${userId}&pagination=false`,
        })
      );
    }
  }, []);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      if (isResolvePopupOpen) {
        setIsResolvePopupOpen(false);
      }
    }
  }, [notification]);
  //
  useEffect(() => {
    let formattedParamString = Object.entries(params)
      .filter(([, paramValue]) => paramValue && paramValue !== 'All' && paramValue !== '')
      .filter(([paramKey]) => paramKey !== 'sortByField' && paramKey !== 'sortBy')
      .map(([paramKey, paramValue]) => `${paramKey}=${paramValue}`)
      .join('&');
    formattedParamString += `&sortBy=${pageController.sortByField}:${pageController.sortBy}`;
    if (searchParams.get('type') !== type) {
      setSearchParams({ type });
    }
    dispatch(supportTicketActions.getSupportTickets({ query: formattedParamString }));
  }, [params, updatedSupportTicket]);
  //
  useEffect(() => {
    if (searchParams) {
      const param = new URLSearchParams(searchParams);
      const typeParam = param.get('type');
      if (typeParam) {
        setType(typeParam);
      } else {
        setSearchParams({ type: SUPPORT_TICKET_FILTER_TYPES.RAISED });
      }
    }
  }, []);
  //
  useEffect(
    () => () => {
      dispatch(supportTicketActions.resetSupportTickets());
    },
    []
  );
  //
  const handleTitleOnClick = (event, id) => {
    window.open(ROUTES.SINGLE_SUPPORT_TICKET.replace(':id', id), '_blank');
  };
  //
  const getTableColumns = () => {
    if (ticketReadPermissionsVerified && type === SUPPORT_TICKET_FILTER_TYPES.HANDLING) {
      return SUPPORT_TICKET_HANDLING_TABLE_COLUMNS;
    }
    return SUPPORT_TICKET_RAISED_TABLE_COLUMNS;
  };
  //
  const handleCommentOnClick = (selectedTicketId) => {
    dispatch(supportTicketActions.setIsInitialCommentFetching(true));
    dispatch(supportTicketActions.getComments({ id: selectedTicketId }));
    setCommentSectionModelOpen(true);
    setTicketId(selectedTicketId);
  };
  //
  const handleMonthChange = (e) => {
    initialize();
    const UserSelectedMonth = moment(e.$d).month() + 1;
    const UserSelecteYear = moment(e.$d).year();
    const UserSelectedMonthString = UserSelectedMonth.toString();
    const UserSelecteYeaString = UserSelecteYear.toString();
    setMonthChange(e.$d);
    setAPIformattedMonth(`${UserSelectedMonthString}.${UserSelecteYeaString}`);
  };
  //
  const handleClear = () => {
    initialize();
    setMonthChange(null);
    setAPIformattedMonth(null);
  };
  //
  return (
    <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
      <ConfirmationPopup
        open={isResolvePopupOpen}
        displayMessage="Do you really want to Resolve this item?"
        handleOnClose={() => setIsResolvePopupOpen(false)}
        handleOnConfirmation={handleOnConfirmationSubmit}
        loading={ticketStatusLoading}
      />
      <Grid container direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: { xs: '10px', sm: 0 } }}>
          Support Tickets
        </Typography>
        <Button onClick={() => setAddModalOpen(true)} sx={{ width: { xs: '100%', sm: 'auto' } }}>
          Add Support Ticket <AddIcon />
        </Button>
      </Grid>
      <AddSupportTicketsPopup addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />
      <Grid container alignItems="center" mb={3}>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          spacing={4}
        >
          <Grid item xs={12} md={12} lg={3} sx={{ mb: { xs: '10px', sm: 0 } }}>
            <Box sx={{ height: '60px', width: '100% !important' }}>
              <Autocomplete
                id="project-selector"
                name="project-selector"
                options={getOptions()}
                label="Project"
                controlled
                value={projectValue}
                disableClearable
                onChange={(_, newValue) => {
                  initialize();
                  setProjectId(newValue?.id || '');
                  setProject(newValue?.label || 'All');
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={2} sx={{ mb: { xs: '10px', sm: 0 } }}>
            <Box sx={{ height: '60px', width: '100% !important' }}>
              <SelectBox
                items={[
                  { key: 'All', value: 'All' },
                  ...Object.values(SEVERITY_TYPES).map((severityType) => ({
                    key: severityType,
                    value: severityType,
                  })),
                ]}
                id="severity-selector"
                name="severtiy-selector"
                textLabel="Severity"
                onChange={(e) => {
                  initialize();
                  setSeverity(e.target.value);
                }}
                value={capitalizeFirstLetter(severityValue)}
                stringFormat={capitalizeFirstLetter}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={2} sx={{ mb: { xs: '10px', sm: 0 } }}>
            <Box sx={{ height: '60px', width: '100% !important' }}>
              <SelectBox
                items={[
                  { key: 'All', value: 'All' },
                  ...Object.values(SUPPORT_TICKET_STATUS).map((ticketStatus) => ({
                    key: ticketStatus,
                    value: ticketStatus,
                  })),
                ]}
                id="status-selector"
                name="status-selector"
                textLabel="Status"
                onChange={(e) => {
                  initialize();
                  setStatus(e.target.value);
                }}
                value={capitalizeFirstLetter(statusValue)}
                stringFormat={capitalizeFirstLetter}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={2} sx={{ mb: { xs: '10px', sm: 0 } }}>
            <Box sx={{ height: '60px', width: '100% !important' }}>
              <SelectBox
                items={SUPPORT_TICKET_SORTBY_FIELDS}
                textLabel="Sort By Field"
                onChange={(_, key) => {
                  initialize();
                  setPageController({ ...pageController, sortByField: key.key.replace('.$', '') });
                }}
                value={pageController.sortByField}
                renderValue={(valll) =>
                  SUPPORT_TICKET_SORTBY_FIELDS.find((item) => item.key === valll)?.value
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={2} sx={{ mb: { xs: '10px', sm: 0 } }}>
            <Box sx={{ height: '60px', width: '100% !important' }}>
              <SelectBox
                items={[
                  ...Object.values(SORT_TYPES).map((sortType) => ({
                    key: sortType,
                    value: sortType,
                  })),
                ]}
                textLabel="Sort By"
                onChange={(e) => {
                  initialize();
                  setPageController({ ...pageController, sortBy: e.target.value });
                }}
                value={pageController.sortBy}
                stringFormat={toUpperCase}
                renderValue={toUpperCase}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={3} sx={{ mb: { xs: '10px', sm: 0 } }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="select a month"
                  views={['month', 'year']}
                  value={monthChange}
                  onChange={handleMonthChange}
                  placeholder="Select month"
                />
              </LocalizationProvider>
              <IconButton onClick={handleClear} sx={{ ml: 1 }}>
                <ClearIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box mb={6}>
        <PermissionWrapper
          requiredPermissions={[
            {
              domain: PERMISSION_DOMAINS.TICKET_HANDLING,
              action: PERMISSION_ACTIONS.READ,
            },
          ]}
          hide
        >
          <Tabs
            value={type}
            onChange={(__, newValue) => {
              dispatch(supportTicketActions.setInitialState());
              setPage(1);
              setType(newValue);
              setProjectId('');
              setProject('All');
              setSeverity('All');
              setStatus('All');
            }}
            indicatorColor="primary"
            centered
            scrollButtons="auto"
            allowScrollButtonsMobile
            variant="fullWidth"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: (theme) => theme.palette.info.main,
              },
              '& .MuiTabScrollButton-root.Mui-disabled': {
                opacity: 0.3,
              },
            }}
          >
            <Tab
              sx={{
                '&.Mui-selected': {
                  backgroundColor: (theme) => theme.palette.info.main,
                  color: (theme) => theme.palette.common.white,
                  transitionDuration: '0.5s',
                },
                '&.MuiTab-root': {
                  fontSize: '1rem',
                  textTransform: 'none',
                },
                '&:hover:not(.Mui-selected)': {
                  backgroundColor: COLORS.SILVER_LILAC,
                },
                bgcolor: COLORS.TRANSPARENT_DARK_GREEN,
                borderRadius: '0.2rem',
              }}
              value={SUPPORT_TICKET_FILTER_TYPES.RAISED}
              label="Raised support tickets"
            />
            <Tab
              sx={{
                '&.Mui-selected': {
                  backgroundColor: (theme) => theme.palette.info.main,
                  color: (theme) => theme.palette.common.white,
                  transitionDuration: '0.5s',
                },
                '&.MuiTab-root': {
                  fontSize: '1rem',
                  textTransform: 'none',
                },
                '&:hover:not(.Mui-selected)': {
                  backgroundColor: COLORS.SILVER_LILAC,
                },
                bgcolor: COLORS.TRANSPARENT_DARK_GREEN,
                borderRadius: '0.2rem',
              }}
              value={SUPPORT_TICKET_FILTER_TYPES.HANDLING}
              label="Handling support tickets"
            />
          </Tabs>{' '}
        </PermissionWrapper>
      </Box>
      {isFirst ? (
        <ShimmerTable row={4} col={8} />
      ) : (
        <InfiniteScroll
          dataLength={supportTickets?.docs?.length ?? 0}
          next={fetchNextPage}
          hasMore={supportTickets?.hasNextPage}
          loader={
            <Box textAlign="center" margin="1.125rem">
              <CircularProgress size={30} />
            </Box>
          }
        >
          <DataGrid
            columns={getTableColumns()}
            rows={supportTickets?.docs?.map((supportTicket) => ({
              ...supportTicket,
              id: supportTicket?.id,
              key: supportTicket?.id,
              project: {
                value: supportTicket?.projectId?.name,
                onClick: (event) => {
                  event.stopPropagation();
                  handleTitleOnClick(event, supportTicket?.id);
                },
              },
              raisedBy: {
                value: supportTicket?.raisedBy?.firstName.concat(
                  ' ',
                  supportTicket?.raisedBy?.lastName
                ),
              },
              title: {
                value: supportTicket?.title,
                onClick: (event) => {
                  event.stopPropagation();
                  handleTitleOnClick(event, supportTicket?.id);
                },
              },
              description: {
                value: supportTicket?.description,
              },
              severity:
                type === SUPPORT_TICKET_FILTER_TYPES.RAISED
                  ? {
                      severity: supportTicket?.severity,
                      onChange: (event) => {
                        event.stopPropagation();
                        dispatch(
                          supportTicketActions.updateSeverity({
                            id: supportTicket?.id,
                            severity: event.target.value,
                          })
                        );
                      },
                    }
                  : { severity: supportTicket?.severity },
              createdAt: {
                value: new Date(supportTicket?.createdAt).toLocaleDateString(
                  DATE_LANGUAGE.LANGUAGE,
                  DATE_FORMAT_SLASH
                ),
              },
              updatedAt: {
                value: new Date(supportTicket?.updatedAt).toLocaleDateString(
                  DATE_LANGUAGE.LANGUAGE,
                  DATE_FORMAT_SLASH
                ),
              },
              attachments: {
                value: supportTicket?.attachments,
              },
              dataStatus: {
                value: supportTicket?.acknowledged?.isAcknowledged,
                onClick: (event) => {
                  event.stopPropagation();
                  if (supportTicket?.acknowledged?.isAcknowledged) return;
                  setTicketToResolve(supportTicket);
                  setIsResolvePopupOpen(true);
                },
              },
              jiraIssueId: {
                value: supportTicket?.jiraIssueId,
              },
              loggedTime: {
                value: supportTicket?.loggedTime ? supportTicket?.loggedTime?.toFixed(2) : 0,
              },
              comments: {
                handleCommentOnClick: (event) => {
                  event.stopPropagation();
                  handleCommentOnClick(supportTicket?.id);
                },
              },
              openInNewTab: {
                handleOpenInNewTabOnClick: (event) => {
                  event.stopPropagation();
                  window.open(
                    ROUTES.SINGLE_SUPPORT_TICKET.replace(':id', supportTicket?.id),
                    '_blank'
                  );
                },
              },
            }))}
            page={0}
            totalPages={supportTickets?.hasNextPage ? supportTickets?.totalPages ?? 0 : 0}
            totalDocs={supportTickets?.totalDocs ?? 0}
            limit={
              !supportTickets?.prevPage
                ? pageController.rowsPerPage
                : supportTickets?.docs?.length ?? 0
            }
            loading={isLoading && isFirst !== true}
            rowHeight={supportTickets?.docs?.length ? 52 : 200}
            clickable
          />
        </InfiniteScroll>
      )}
      <CommentSectionPopup
        commentSectionModelOpen={commentSectionModelOpen}
        setCommentSectionModelOpen={setCommentSectionModelOpen}
        ticketId={ticketId}
        setTicketId={setTicketId}
      />
    </Container>
  );
};
//
export default SupportTicketView;
